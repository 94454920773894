import React from 'react';
import "assets/scss/anime.scss";
function Spinner() {
    return (
      // <img
      //   className="spinner"
      //   width="24"
      //   height="24"
      //   src="https://i.pinimg.com/originals/3e/f0/e6/3ef0e69f3c889c1307330c36a501eb12.gif"
      // />    
    <div className="scene">
      <div className="wrap">
          <div className="wall wall-right"></div>
          <div className="wall wall-left"></div>   
          <div className="wall wall-top"></div>
          <div className="wall wall-bottom"></div> 
          <div className="wall wall-back"></div>    
      </div>
      <div className="wrap">
          <div className="wall wall-right"></div>
          <div className="wall wall-left"></div>   
          <div className="wall wall-top"></div>
          <div className="wall wall-bottom"></div>   
          <div className="wall wall-back"></div>    
      </div>
    </div>
    );
  }
  
  export default Spinner
/*!

=========================================================
* BLK Design System React - v1.2.0
=========================================================

* Product Page: https://www.creative-tim.com/product/blk-design-system-react
* Copyright 2020 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/blk-design-system-react/blob/main/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React from "react";
import "../assets/scss/anime.scss";
export default function AnimePage() {
  return (
    <div className="scene">
      <div className="wrap">
          <div className="wall wall-right"></div>
          <div className="wall wall-left"></div>   
          <div className="wall wall-top"></div>
          <div className="wall wall-bottom"></div> 
          <div className="wall wall-back"></div>    
      </div>
      <div className="wrap">
          <div className="wall wall-right"></div>
          <div className="wall wall-left"></div>   
          <div className="wall wall-top"></div>
          <div className="wall wall-bottom"></div>   
          <div className="wall wall-back"></div>    
      </div>
    </div>
  );
}

/*!

=========================================================
* BLK Design System React - v1.2.0
=========================================================

* Product Page: https://www.creative-tim.com/product/blk-design-system-react
* Copyright 2020 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/blk-design-system-react/blob/main/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React, { Suspense } from "react";
import ReactDOM from "react-dom";
import { BrowserRouter, Route, Switch, Redirect } from "react-router-dom";

import "assets/css/nucleo-icons.css";
import "assets/scss/blk-design-system-react.scss?v=1.2.0";
import "assets/demo/demo.css";

import LandingPage from "views/LandingPage.js";
import GamePage from "views/GamePage.js";
import AnimePage from "views/animetest.js";
import './i18n';

ReactDOM.render(
  <BrowserRouter>
    <Suspense fallback={<div>Loading..</div>}>
      <Switch>
        <Route path="/landing-page" render={(props) => <LandingPage {...props} />} />
        <Route
          path="/game-page"
          render={(props) => <GamePage {...props} />}
        />
        <Route
          path="/anime-test"
          render={(props) => <AnimePage {...props} />}
        />
        {/* <Route
          path="/register-page"
          render={(props) => <RegisterPage {...props} />}
        /> */}
        {/* <Route
          path="/profile-page"
          render={(props) => <ProfilePage {...props} />}
        /> */}
        <Redirect from="/" to="/landing-page" />
      </Switch>
    </Suspense>
  </BrowserRouter>,
  document.getElementById("root")
);

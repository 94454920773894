/*!

=========================================================
* BLK Design System React - v1.2.0
=========================================================

* Product Page: https://www.creative-tim.com/product/blk-design-system-react
* Copyright 2020 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/blk-design-system-react/blob/main/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React ,{ useState }from "react";
import classnames from "classnames";
// javascript plugin used to create scrollbars on windows
import PerfectScrollbar from "perfect-scrollbar";
// reactstrap components
import {
  Button,
  Card, 
  CardBody,
  FormGroup,
  Input,
  Container,
  Row,
  Col,
  CardFooter,
  Modal,
  ModalBody,
  ListGroupItem,
  ListGroup,
  Table
} from "reactstrap";

// core components
import MainNavbar from "components/Navbars/MainNavbar.js";
import { useMediaQuery } from 'react-responsive';
import Slider from "components/Slider/slider.js";
import { useTranslation } from "react-i18next";
import Spinner from "components/Spinner/spinner";
import Spinner1 from "components/Spinner/spinner1";
import Spinner2 from "components/Spinner/spinner2";
import axios from "axios";
import LiveChat from 'react-livechat'

import LoadingOverlay from 'react-loading-overlay';

export default function GamePage() {

  const isDesktopOrLaptop = useMediaQuery({
    query: '(min-width: 1200px)'
  });
  const isTabletOrMobile = useMediaQuery({ 
    query: '(max-width: 1023px)' 
  });
  const smallMin = useMediaQuery({ 
    query: '(min-width: 398px)' 
  });
  const smallMax = useMediaQuery({ 
    query: '(max-width: 766px)' 
  });
  const tabletMin = useMediaQuery({ 
    query: '(min-width: 1024px)' 
  });
  const tabletMax = useMediaQuery({ 
    query: '(max-height: 1366px)' 
  });
  const tableMaxw = useMediaQuery({ 
    query: '(max-width: 1199px)' 
  });
  const isSmallMobile = (() => {
    if (smallMin && smallMax) {
      return true;
    } else {
      return false;
    }
  })();
  const isTablet = (() => {
    if (tabletMin && tabletMax && tableMaxw) {
      return true;
    } else {
      return false;
    }
  })();
  const { t, i18n } = useTranslation();
  const gameImg = {
    position : "relative"
  };
  const unsetImgWidth = {
    width: "unset"
  }
  const gameImgText = {
    left: "0",
    position:"absolute",
    textAlign:"center",
    top: "35%",
    width: "100%"
  };
  const gameImgInnerText = {
    background: "rgba(0,0,0,0.6)",
    width: "50%",
    left: "26%",
    position:"absolute",
    borderRadius: "25px",
    border: "1px black solid",
    fontSize: "24px",
    fontWeight: "bold"
  };
  const ptop = {
    paddingTop : "7vh"
  };
  const noptop = {
    padding : "0",
    marginTop: "1em"
  }
  const sectionCardText = {
    color: "rgba(255, 255, 255, 0.8)",
    fontWeight: "300",
    textAlign: "center"
  };
  const sectionCardTextNC = {
    color: "rgba(255, 255, 255, 0.8)",
    fontWeight: "300"
  };
  const f20 = {
    fontSize: "20px",
  };
  const f30 = {
    fontSize: "30px",
  };
  const midInput = (() => {
    if (isDesktopOrLaptop) {
      return {
        height: "3em",
        width: "5em",
        fontSize : "24px",
        display: "inline-block",
        marginLeft : "unset",
        borderColor : "#ff9f43"
      };
    } else {
      return {
        height: "3em",
        width: "5em",
        fontSize : "24px",
        display: "inline-block",
        marginLeft : "1em",
        borderColor : "#ff9f43"
      };
    }
  })();
  const midButton = (() => {
    if (isDesktopOrLaptop) {
      return {
        fontSize : "24px",
        height: "2.8em",
        top: "-5px",
        marginRight : "0.5em"
      };
    } else if (isSmallMobile) {
      return {
        fontSize : "24px",
        height: "2.8em",
        top: "unset",
        marginRight : "unset"
      };
    } else if (isTabletOrMobile) {
      return {
        fontSize : "24px",
        height: "2.8em",
        top: "5px",
        marginRight : "unset"
      };
    } else {
      return {
        fontSize : "24px",
        height: "2.8em",
        top: "unset",
        marginRight : "unset"
      };
    }
  })();
  const midButtonSingle = (() => {
    if (isDesktopOrLaptop) {
      return {
        fontSize : "24px",
        height: "2.8em",
        top: "-5px",
        marginRight : "0.5em"
      };
    } else if (isSmallMobile) {
      return {
        fontSize : "24px",
        height: "3em",
        top: "-3px",
        marginRight : "unset"
      };
    } else if (isTablet) {
      return {
        fontSize : "24px",
        height: "3em",
        top: "-3px",
        marginRight : "unset"
      };
    } else if (isTabletOrMobile) {
      return {
        fontSize : "24px",
        height: "2.8em",
        top: "5px",
        marginRight : "unset"
      };
    } else {
      return {
        fontSize : "24px",
        height: "2.8em",
        top: "unset",
        marginRight : "unset"
      };
    }
  })();
  const centerAll = {
    textAlign : "center"
  };
  const centerAllw100 = {
    width: "100%",
    textAlign : "center"
  }
  const centerAllw100mt2 = {
    width: "100%",
    marginTop : "2em",
    textAlign : "center"
  }
  const maxText = {
    position: "inherit",
    top: "-2em",
    left: "-2.4em",
    color: "rgba(255, 165, 2, 1)"
  };
  
  const searchText = (() => {
    if (isDesktopOrLaptop) {
      return {
        display: "inline-block",
        width: "8em",
        overflowWrap : "break-word",
        fontSize: "12px",
        top: "1.5em",
        marginLeft : "40%"
      };
    } else {
      return {
        display: "inline-block",
        width: "8em",
        overflowWrap : "break-word",
        fontSize: "12px",
        top: "1.5em",
        marginLeft : "unset"
      };
    }
  })();
  
  const searchTextSingle = (() => {
    if (isDesktopOrLaptop) {
      return {
        display: "inline-block",
        width: "8em",
        overflowWrap : "break-word",
        fontSize: "12px",
        top: "1.5em",
        // marginLeft : "13%"
      };
    } else if (isSmallMobile) {
      return {
        display: "inline-block",
        width: "8em",
        overflowWrap : "break-word",
        fontSize: "12px",
        top: "1.5em",
        marginLeft : "unset"
      };
    } else if (isTabletOrMobile) {
      return {
        display: "inline-block",
        width: "8em",
        overflowWrap : "break-word",
        fontSize: "12px",
        top: "1.5em",
        marginLeft : "unset"
      };
    } else {
      return {
        display: "inline-block",
        width: "8em",
        overflowWrap : "break-word",
        fontSize: "12px",
        top: "1.5em",
        marginLeft : "unset"
      };
    }
  })();

  const searchTextSingle1 = (() => {
    if (isDesktopOrLaptop) {
      return {
        display: "inline-block",
        width: "8em",
        overflowWrap : "break-word",
        fontSize: "12px",
        top: "1.5em",
        marginLeft : "-3%",
        marginRight: "7%"
      };
    } else if (isSmallMobile) {
      return {
        overflowWrap : "break-word",
        fontSize: "12px",
        marginTop: "0.5em"
      };
    } else if (isTablet) {
      return {
        display: "inline-block",
        width: "8em",
        overflowWrap : "break-word",
        fontSize: "12px",
        top: "1.5em",
        marginLeft : "unset",
        marginRight: "9%"
      };
    } else if (isTabletOrMobile) {
      return {
        overflowWrap : "break-word",
        fontSize: "12px",
        marginTop: "0.5em"
      };
    } else {
      return {
        display: "inline-block",
        width: "8em",
        overflowWrap : "break-word",
        fontSize: "12px",
        top: "1.5em",
        marginLeft : "unset"
      };
    }
  })();
  // const mt2 = {
  //   marginTop : "2em"
  // };
  const mt2 = (() => {
    if (isDesktopOrLaptop) {
      return {
        marginTop : "2em"
      };
    } else {
      return {
      };
    }
  })();
  const hiddenel = {
    display: "none"
  };
  const w100 = {
    width: "100%"
  }
  const w99 = {
    width: "99%"
  }
  const nomh = {
    maxHeight : "unset"
  }

  const unsetmargins = (() => {
    if (isDesktopOrLaptop) {
      return {
        marginLeft : "unset",
        marginRight : "unset"
      };
    } else {
      return {
        marginLeft : "unset",
        marginRight : "unset"
      };
    }
  })();

  const loadSpinner = (() => {
    if (isDesktopOrLaptop) {
      return {
        marginTop : "25%"
      };
    } else if (isSmallMobile) {
      return {
        marginTop : "100%"
      };
    } else if (isTabletOrMobile) {
      return {
        marginTop : "60%"
      };
    } else {
      return {
        marginTop : "25%"
      };
    }
  })();

  const widthSetter = (() => {
    if (isTabletOrMobile) {
      return {
        width : "70%",
        left : "15%",
        fontSize : "20px"
      };
    } else {
      return {
      };
    }
  })();
  const lh50 = {
    lineHeight: "50",
  };
  const mbfsz = (() => {
    if (isDesktopOrLaptop) {
      return {
        fontSize: "20px"
      };
    } else if (isSmallMobile) {
      return {
        fontSize: "15px"
      };
    } else if (isTablet) {
      return {
        fontSize: "18px"
      };
    } else if (isTabletOrMobile) {
      return {
        fontSize: "15px"
      };
    } else {
      return {
        fontSize: "20px"
      };
    }
  })();

  const [tabs, setTabs] = React.useState(1);
  
  const [table1Input, setTable1Input] = React.useState(1);
  const [table2Input, setTable2Input] = React.useState();
  const [table3Input, setTable3Input] = React.useState();
  const [poolAmt, setPoolAmt] = React.useState("");
  const [averageTimeMin, setAverageTimeMin] = React.useState("");
  const [averageTimeSec, setAverageTimeSec] = React.useState("");

  const [rollingAmount, setRollingAmount] = React.useState("");
  const [activePlayers, setActivePlayers] = React.useState("");

  const [blockNo, setBlockNo] = React.useState("TxxxxXX");
  const [isDisplay , setIsDisplay]= React.useState(false);
  const [isDisplayWinGif , setIsDisplayWinGif]= React.useState(false);
  const [isDisplayLoseGif , setIsDisplayLoseGif]= React.useState(false);

  const [isLoading , setIsLoading]= React.useState(false);
  // USDT shasta testnet = "TMBCEYxoK5uFLqPvFc4nQCRvuGQtZ1oq5A"
  // USDT main net = "TR7NHqjeKQxGTCi8q8ZY4pL8otSzgjLj6t"
  const usdt = "TR7NHqjeKQxGTCi8q8ZY4pL8otSzgjLj6t";

  // table1 main net = "TJSJQiogez25juTtk1JrzQA5YK7etPUUUU"
  // table1 test net = "TBFPNbcst15xzMvQrCp2tU2LyVgj4zL9n7"
  const table1 = "TJSJQiogez25juTtk1JrzQA5YK7etPUUUU";
  const table2 = "TFKCZdFWxybJRbTBCT4GifBDNihkNAQQQQ";
  const table3 = "TVSgUKULcYTEH6hy2xNZVpt5QFUeSPKKKK";

  const [isLogin , setIsLogin] = React.useState(false);
  const [enableLiveChat , setEnableLiveChat] = React.useState(false);
  const [balance , setBalance] = React.useState("");
  const [table1Wallet , setTable1Wallet] = React.useState("TQ9hGroJxD3PPCHCtrrHf17QQkn4soUBET");
  const [mainWallet, setMainWallet] = React.useState("T...UBET");
  const [winAmt ,setWinAmt] = React.useState(0);
  const getBalance = async () => {
    console.log("getBalance");
    //if wallet installed and logged , getting TRX token balance
    if (window.tronWeb && window.tronWeb.ready) {
      console.log("window.tronWeb.address", window.tronWeb.address);
      const { abi } = await window.tronWeb.trx.getContract(usdt);
      const contract = window.tronWeb.contract(abi.entrys, usdt);
      var obj = JSON.parse(window.sessionStorage.getItem("login"))
      // console.log(contract);
      const balance = await contract.methods.balanceOf(obj.address).call();
      console.log("balance:", balance.toString());
      setBalance((balance/1000000).toString());
      // return walletBalances;
    } else {
      return 0;
    }
  };

  const getPool =  async () =>{
    // let response = await axios.get('https://us-central1-tron-8d709.cloudfunctions.net/pool');
    // if(response.status == 200){
    //   if(response.data){
    //     setPoolAmt(response.data.pool);
    //     setIsLoading(false);
    //   }    
    // }   
    setPoolAmt("21,383 USDT");
  }

  const getAverageTime = async () =>{
    // let response = await axios.get('http://18.119.28.96:5000/AverageTime');
    // if(response.status == 200){
    //   console.log(response.data);
    //   // var result = JSON.parse(response.data);
    
    //   setAverageTimeMin(response.data.minute);
    //   setAverageTimeSec(response.data.second);
    // }
    setAverageTimeMin("0");
    setAverageTimeSec("24.3");
  }

  const getActivePlayers = async () =>{
    // let response = await axios.get('http://18.119.28.96:5000/ActivePlayers');
    // if(response.status == 200){
    //   setActivePlayers(response.data);
    // }
    setActivePlayers("57");
  }

  const getRollingAmount = async () =>{
    // let response = await axios.get('http://18.119.28.96:5000/RollingAmount');
    // if(response.status == 200){
    //   console.log(response.data); 
    //   setRollingAmount(response.data);
    // }
    setRollingAmount("25,600 USDT");
    // setIsLoading(false);
  }


  const navigatePool1 = () => {
    // window.open("https://tronscan.org/#/address/TBFPNbcst15xzMvQrCp2tU2LyVgj4zL9n7");
    window.open("https://tronscan.org/#/address/" + table1Wallet);
  }
  const navigatePool2 = () => {
    window.open("https://tronscan.org/#/address/TFKCZdFWxybJRbTBCT4GifBDNihkNAQQQQ");
  }

  const navigatePool3 = () => {   
      window.open("https://tronscan.org/#/address/TVSgUKULcYTEH6hy2xNZVpt5QFUeSPKKKK");
  }

  const triggerSmartContract = async (account , amount) => {
    try {
        let contract = await window.tronWeb.contract().at(usdt);
        //Use send to execute a non-pure or modify smart contract method on a given smart contract that modify or change values on the blockchain.
        // These methods consume resources(bandwidth and energy) to perform as the changes need to be broadcasted out to the network.
        let result = await contract.transfer(
            account, //address _to
            amount   //amount
        ).send({
            feeLimit: 100000000
        })
        console.log('result: ', result);
        return result;
    } catch(error) {
        console.error("trigger smart contract error",error)
    }
  }

  function getTransactionBlockNumber(txHash, interval) {
    const self = this;
    const transactionReceiptAsync = function (resolve, reject) {
      window.tronWeb.trx.getUnconfirmedTransactionInfo(txHash, (error, receipt) => {
        if (error) {
          reject(error);
        } else if (receipt.blockNumber == null) {
          setTimeout(
            () => transactionReceiptAsync(resolve, reject),
            interval ? interval : 1000
          );
        } else {
          // check for receipt.result, which should be SUCCESS
          if(receipt.receipt.result == "SUCCESS"){
            resolve(receipt.blockNumber);
          }else{
            resolve("");
          }
        }
      });
    };

    return new Promise(transactionReceiptAsync);
  };

  const getBlockInfo= async (blockNo) => {
    var result = await window.tronWeb.trx.getBlockByNumber(blockNo);
    return result;
  }

  const onChangeTable1 = (event) =>{
    if(event > 5000){
      setTable1Input(5000);
    }
    else{
      setTable1Input(event);
    }  

  }

  const onChangeTable2 = (event) =>{
    if(event > 500){
      setTable2Input(500);
    }
    else{
      setTable2Input(event);
    }  
  }

  const onChangeTable3 = (event) =>{
    if(event > 1000){
      setTable3Input(1000);
    }
    else{
      setTable3Input(event);
    }  
  }

  const submitTable1 = async () => {
    
    if (window.sessionStorage.getItem("login")) {
      if (table1Input >= 1) {
        // let tempBalance = await getBalance();
        // if(tempBalance >= parseInt(table1Input + "000000")){
        setIsDisplay(true);
        var transferResponse = triggerSmartContract(table1Wallet,parseInt(table1Input + "000000"));
        transferResponse.then(function (txnId) {
          console.log("txnId:", txnId);
          if(txnId != undefined && txnId != ""){
            var blockNumber = getTransactionBlockNumber(txnId);
            
            blockNumber.then(function (blockNumber) {
              if(blockNumber != undefined && blockNumber != ""){
                console.log("blockNumber:", blockNumber);
                var blockInfo = getBlockInfo(blockNumber);
                blockInfo.then(function (blockInfo) {
                  console.log("blockHash:", blockInfo);
  
                  let last2Char = blockInfo.blockID.slice(-2);
                  var containsNumber = false;
  
                  let matchPattern = last2Char.match(/\d+/g);
                  if (matchPattern != null) {
                    containsNumber = true;
                  }
  
                  var containsChar = isNaN(last2Char);
                  if (containsNumber && containsChar) {
                    setIsDisplay(false);
                    setBlockNo("Txxxx" + last2Char);
                    setIsDisplayWinGif(true);
                    setWinAmt(table1Input * 2);
                    // setTimeout(function () {
                    //   setIsDisplayWinGif(false);
                    // }, 5000);
                  } else {
                    setIsDisplay(false);
                    setBlockNo("Txxxx" + last2Char);
                    setIsDisplayLoseGif(true);
                    setWinAmt(0);
                    // setTimeout(function () {
                    //   setIsDisplayLoseGif(false);
                    // }, 5000);
                  }
                  setTable1Input("");
                });
              }
              else{
                setIsDisplay(false);
                alert("Transaction Failed");
              }
            });
          }
          else{
            setIsDisplay(false);
            alert("Transaction Failed");
          }
        });

      } else {
        alert("The minimum amount is 1.");
      }
    }
    else {
      alert("Please connect to your wallet.");
    }
  }
  
  const submitTable2 = async () =>{
    if(window.sessionStorage.getItem("login"))
    {
      if(table2Input >= 10)
      {
        setIsDisplay(true); 
        var transferResponse = triggerSmartContract(table2, parseInt(table2Input + "000000"))  
        
        transferResponse.then(function (txnId) {
          console.log("txnId:", txnId);
          var blockNumber = getTransactionBlockNumber(txnId);
          blockNumber.then(function (blockNumber) {
            console.log("blockNumber:", blockNumber);
            var blockInfo = getBlockInfo(blockNumber);
            blockInfo.then(function (blockInfo) {
              console.log("blockHash:", blockInfo);

              let last2Char = blockInfo.blockID.slice(-2);
              var containsNumber = false;

              let matchPattern = last2Char.match(/\d+/g);
              if (matchPattern != null) {
                containsNumber = true;
              }
              var containsChar = isNaN(last2Char);
              if (containsNumber && containsChar) 
              {
                setIsDisplay(false);
                setIsDisplayWinGif(true);
                setTimeout(function () {
                  setIsDisplayWinGif(false);
                }, 3000);
              } 
              else 
              {
                setIsDisplay(false);
                setIsDisplayLoseGif(true);
                setTimeout(function () {
                  setIsDisplayLoseGif(false);
                }, 3000);
              }
              setTable2Input("");

            });
          });
        });
      }
      else
      {
        alert("The minimum amount is 10.");
      }
    }
    else
    {
      alert("Please connect to your wallet.");
    }
  }
  
  const submitTable3 = async () =>{
    if(window.sessionStorage.getItem("login"))
    {
      if(table3Input >= 20){
          setIsDisplay(true); 
          var transferResponse = triggerSmartContract(table3, parseInt(table3Input + "000000"))                
          transferResponse.then(function (txnId) 
          {
            console.log("txnId:", txnId);
            var blockNumber = getTransactionBlockNumber(txnId);
            blockNumber.then(function (blockNumber) {
              console.log("blockNumber:", blockNumber);
              var blockInfo = getBlockInfo(blockNumber);
              blockInfo.then(function (blockInfo) {
                console.log("blockHash:", blockInfo);
  
                let last2Char = blockInfo.blockID.slice(-2);
                var containsNumber = false;
  
                let matchPattern = last2Char.match(/\d+/g);
                if (matchPattern != null) {
                  containsNumber = true;
                }
                var containsChar = isNaN(last2Char);
                if (containsNumber && containsChar) {
                  setIsDisplay(false);
                  setIsDisplayWinGif(true);
                  // setTimeout(function () {
                  //   setIsDisplayWinGif(false);
                  // }, 3000);
                } else {
                  setIsDisplay(false);
                  setIsDisplayLoseGif(true);
                  // setTimeout(function () {
                  //   setIsDisplayLoseGif(false);
                  // }, 3000);
                }
                setTable3Input("");
              });
            });
          });      
      }
      else
      {
        alert("The minimum amount is 20.");
      }
    }
    else{
      alert("Please connect to your wallet.");
    }
  }

  React.useEffect(() => {
    setIsLoading(true);

    var hostName = window.location.hostname;

    if(hostName.includes("utron")){
      setTable1Wallet(table1);
      setMainWallet("T...UUUU");
    } else if (hostName.includes("1687")){
      setTable1Wallet(table2);
      setMainWallet("T...QQQQ");
    }

    if(!hostName.includes("utron"))
    {
      setEnableLiveChat(true);
    }

     getPool().then( () => {
       getActivePlayers().then(() =>{
        getAverageTime().then(() =>{
          getRollingAmount();
        });
       }); 
     });

    getBalance();
    const interval=setInterval(()=>{
      getBalance()
    },10000)

    setIsLoading(false);

    return function cleanup() {
      clearInterval(interval)
    };
  },[]);
  
  const handleIsLogin = () =>{
    setIsLogin(!isLogin);
  }
 
  const lastChar = () =>{
    let lastChars = table1Wallet.slice(-4);
    return lastChars;
  }
  
  const firstChar = () =>{
    let firstChars = table1Wallet.substr(0, table1Wallet.length - 4)
    
    return firstChars;
  }

  const toggleLoseGifModal = () => setIsDisplayLoseGif(!isDisplayLoseGif);
  const toggleWinGifModal = () => setIsDisplayWinGif(!isDisplayWinGif);

  return (
    isLoading ? <div style={loadSpinner}><Spinner></Spinner></div> :
    <>
    <LoadingOverlay
      active={isDisplay}
      spinner
      text='Loading...Generating Block Hash...'
      >
      <MainNavbar handleIsLogin = {handleIsLogin}/>
      {/* <Slider></Slider> */}
      <div className="wrapper">
        <div className="page-header" style={nomh}>
          <img
            alt="..."
            className="dots"
            src={require("assets/img/dots.png").default}
          />
          <img
            alt="..."
            className="path"
            src={require("assets/img/path4.png").default}
          />
          <Container className="align-items-center" style={ptop}>
            <Row style={unsetmargins}>
              <Col lg="12" md="12" sm="12" xs="12">
                <Row style={Object.assign(unsetmargins, w99)}>
                <div style={gameImg}>
                  <img
                    alt="..."
                    className="img-center img-fluid"
                    src={require("assets/img/table1.jpeg").default}
                  />
                  <div style={gameImgText}>
                    <p style={Object.assign(gameImgInnerText, widthSetter)}>Table 1<br/>$1-5000</p>
                  </div>
                </div>
                <div style={centerAllw100}>
                  <p className="fw500" style={mbfsz}>{(firstChar())}<a style={{color:"yellow"}}>{lastChar()}</a><br/>1/5000</p>
                </div>
                </Row>
                <Row style={Object.assign(unsetmargins, centerAllw100mt2)}>
                  <FormGroup style={w100}>
                    
                      {(() => {
                        if (isDesktopOrLaptop) {
                          return (
                            <>
                            <Input placeholder="0000" type="text" id="table1" className="form-control" value={table1Input} style={midInput} onChange={(e) => { onChangeTable1(e.target.value); }} onKeyPress={(event) => {if (!/[0-9]/.test(event.key)) { event.preventDefault();}}} />
                            <span onClick={() => {setTable1Input(5000)}} style={maxText}>Max</span> 
                            <Button className="btn-fighter-game" onClick={submitTable1} style={midButtonSingle}><span className="buttonFightTitleGame">&#9876; {t('fight')}</span></Button><br/>
                            <p style={searchTextSingle1}>Balance<br/>{balance} USDT</p>
                            <p style={searchTextSingle}>Search<br/>Game Records</p>
                            </>
                          )
                        } else if (isSmallMobile) {
                          return (
                            <>
                            <Input placeholder="0000" type="text" id="table1" className="form-control" value={table1Input} style={midInput} onChange={(e) => { onChangeTable1(e.target.value); }} onKeyPress={(event) => {if (!/[0-9]/.test(event.key)) { event.preventDefault();}}} />
                            <span onClick={() => {setTable1Input(5000)}} style={maxText}>Max</span> 
                            <p style={searchTextSingle1}>Balance<br/>{balance} USDT</p>
                            <Button className="btn-fighter-game" onClick={submitTable1} style={midButtonSingle}><span className="buttonFightTitleGame">&#9876; {t('fight')}</span></Button><br/>
                            <p style={searchTextSingle}>Search<br/>Game Records</p>
                            </>
                          )
                        } else if (isTablet) {
                          return (
                            <>
                            <Input placeholder="0000" type="text" id="table1" className="form-control" value={table1Input} style={midInput} onChange={(e) => { onChangeTable1(e.target.value); }} onKeyPress={(event) => {if (!/[0-9]/.test(event.key)) { event.preventDefault();}}} />
                            <span onClick={() => {setTable1Input(5000)}} style={maxText}>Max</span> 
                            <Button className="btn-fighter-game" onClick={submitTable1} style={midButtonSingle}><span className="buttonFightTitleGame">&#9876; {t('fight')}</span></Button><br/>
                            <p style={searchTextSingle1}>Balance<br/>{balance} USDT</p>
                            <p style={searchTextSingle}>Search<br/>Game Records</p>
                            </>
                          )
                        } else if (isTabletOrMobile) {
                          return (
                            <>
                            <Input placeholder="0000" type="text" id="table1" className="form-control" value={table1Input} style={midInput} onChange={(e) => { onChangeTable1(e.target.value); }} onKeyPress={(event) => {if (!/[0-9]/.test(event.key)) { event.preventDefault();}}} />
                            <span onClick={() => {setTable1Input(5000)}} style={maxText}>Max</span> 
                            <p style={searchTextSingle1}>Balance<br/>{balance} USDT</p>
                            <Button className="btn-fighter-game" onClick={submitTable1} style={midButtonSingle}><span className="buttonFightTitleGame">&#9876; {t('fight')}</span></Button><br/>
                            <p style={searchTextSingle}>Search<br/>Game Records</p>
                            </>
                          )
                        } else {
                          return (
                            <>
                            <Input placeholder="0000" type="text" id="table1" className="form-control" value={table1Input} style={midInput} onChange={(e) => { onChangeTable1(e.target.value); }} onKeyPress={(event) => {if (!/[0-9]/.test(event.key)) { event.preventDefault();}}} />
                            <span onClick={() => {setTable1Input(5000)}} style={maxText}>Max</span> 
                            <Button className="btn-fighter-game" onClick={submitTable1} style={midButtonSingle}><span className="buttonFightTitleGame">&#9876; {t('fight')}</span></Button><br/>
                            <p style={searchTextSingle1}>Balance<br/>{balance} USDT</p>
                            <p style={searchTextSingle}>Search<br/>Game Records</p>
                            </>
                          )
                        }
                      })()}
                  </FormGroup>
                </Row>
              </Col>
              {/* <Col lg="4" md="4" sm="12" xs="12">
                <Row style={Object.assign(unsetmargins, w99)}>
                <div style={gameImg}>
                  <img
                    alt="..."
                    className="img-center img-fluid"
                    src={require("assets/img/table2.jpeg").default}
                  />
                  <div style={gameImgText}>
                    <p style={Object.assign(gameImgInnerText, widthSetter)}>Table 2<br/>$10-500</p>
                  </div>
                </div>
                <div style={centerAllw100}>
                  <p>TFKCZdFWxybJRbTBCT4GifBDNihkNAQQQQ<br/>10/500</p>
                </div>
                </Row>
                <Row style={Object.assign(unsetmargins, centerAllw100mt2)}>
                  <FormGroup style={w100}>
                      <Input placeholder="0000" type="text" id="table2" className="form-control" value={table2Input} style={midInput} onChange={(e) => { onChangeTable2(e.target.value); }} onKeyPress={(event) => {if (!/[0-9]/.test(event.key)) { event.preventDefault();}}}/>
                      <span onClick={() => {setTable2Input(500)}} style={maxText}>Max</span>
                      <Button className="btn-fighter-game" onClick={submitTable2} style={midButton}><span className="buttonFightTitleGame">&#9876; {t('fight')}</span></Button><br/>
                      <p style={searchText}>Search<br/>Game Records</p>
                  </FormGroup>
                </Row>
              </Col>
              <Col lg="4" md="4" sm="12" xs="12">
                <Row style={Object.assign(unsetmargins, w99)}>
                <div style={gameImg}>
                  <img
                    alt="..."
                    className="img-center img-fluid"
                    src={require("assets/img/table3.jpeg").default}
                  />
                  <div style={gameImgText}>
                    <p style={Object.assign(gameImgInnerText, widthSetter)}>Table 3<br/>$20-1000</p>
                  </div>
                </div>
                <div style={centerAllw100}>
                  <p>TVSgUKULcYTEH6hy2xNZVpt5QFUeSPKKKK<br/>20/1000</p>
                </div>
                </Row>
                <Row style={Object.assign(unsetmargins, centerAllw100mt2)}>
                  <FormGroup style={w100}>
                      <Input placeholder="0000" type="text" id="table3" className="form-control" value={table3Input} style={midInput} onChange={(e) => { onChangeTable3(e.target.value);}} onKeyPress={(event) => {if (!/[0-9]/.test(event.key)) { event.preventDefault();} }}/>
                      <span onClick={() => {setTable3Input(1000)}} style={maxText}>Max</span>
                      <Button className="btn-fighter-game" style={midButton} onClick={submitTable3}><span className="buttonFightTitleGame">&#9876; {t('fight')}</span></Button><br/>
                      <p style={searchText}>Search<br/>Game Records</p>
                  </FormGroup>
                </Row>
              </Col> */}
            </Row>
            <Row className="justify-content-between" style={mt2}>
              <Col xl="4" lg="4" md="12" sm="12">
                <Card className="card-coin card-plain">
                  <CardBody>
                    <div style={sectionCardText}>
                      <p className="numberFont fw400" style={f30}><b>{averageTimeMin}{t('gameBox1Min')}{averageTimeSec}{t('gameBox1Sec')} </b></p>
                      <p className="cardBodyFont" style={f20}>{t('gameBox1Desc1')}</p>
                      <p className="cardBodyFont" style={f20}>{t('gameBox1Desc2')}</p>
                    </div>
                  </CardBody>
                </Card>
              </Col>
              <Col xl="4" lg="4" md="12" sm="12">
                <Card className="card-coin card-plain">
                  <CardBody>
                    <div style={sectionCardText}>
                      <p className="numberFont fw400" style={f30}><b>{rollingAmount}</b></p>
                      <p className="cardBodyFont" style={f20}>{t('gameBox2Desc1')}</p>
                      <p className="cardBodyFont" style={f20}>{t('gameBox2Desc2')}</p>
                    </div>
                  </CardBody>
                </Card>
              </Col>
              <Col xl="4" lg="4" md="12" sm="12">
                <Card className="card-coin card-plain">
                  <CardBody>
                    <div style={sectionCardText}>
                      <p className="numberFont fw400" style={f30}><b>{activePlayers}</b></p>
                      <p className="cardBodyFont" style={f20}>{t('gameBox3Desc1')}</p>
                      <p className="cardBodyFont" style={f20}>{t('gameBox3Desc2')}</p>                     
                    </div>
                  </CardBody>
                </Card>
              </Col>
              <Col xl="12" lg="12" md="12" sm="12">
                <Card className="card-coin card-plain">
                  <CardBody>
                    <div style={sectionCardText}>
                      <p className="numberFont fw400" style={f30}><b>{poolAmt}</b></p>
                      <p className="cardBodyFont" style={f20}>{t('gameBox4Desc1')}</p>
                      <p className="cardBodyFont" style={f20} >
                        <a style={{color:"yellow" ,cursor: "pointer"}} onClick={navigatePool1}>{mainWallet} </a> 
                        {/* <a style={{color:"yellow" ,cursor: "pointer"}} onClick={navigatePool2}>{", T….QQQQ"} </a> */}
                        {/* <a style={{color:"yellow" ,cursor: "pointer"}} onClick={navigatePool3}>{", T….KKKK"} </a> */}
                      </p>
                    </div>
                  </CardBody>
                </Card>
              </Col>
            </Row>
          </Container>
        </div>
        <div className="section" style={noptop}>
          <Container>
          </Container>
        </div>
        <div className="section">
          <Container>
            <Row className="justify-content-between">
              <Col lg="4">
              </Col>
              <Col lg="4">
                <Card className="card-coin card-plain">
                    <CardBody>
                        <div style={sectionCardText}>
                          <p className="cardHeaderFont" style={f30}><b>{t('gameInstruction')}</b></p>
                        </div>
                    </CardBody>
                </Card>
              </Col>
              <Col lg="4">
              </Col>
            </Row>
            <Row className="justify-content-between">
              <Col lg="12">
                <Card className="card-coin card-plain">
                  <CardBody>
                    {/* <img
                      alt="..."
                      className="img-center img-fluid"
                      style={unsetImgWidth}
                      src={require("assets/img/game3.jpg").default}
                    /> */}
                    <Table>
                      <tbody>
                        <tr>
                          <td className="tbBorderNone" scope="row" style={{textAlign:"left"}}><strong>1.</strong>{t("HowToPlay1")}</td>
                          {/* <td className="tbBorderNone"></td> */}
                        </tr>
                        <tr>
                          <td scope="row" style={{textAlign:"left"}}><strong>2.</strong>{t("HowToPlay2")}</td>
                          {/* <td></td> */}
                        </tr>
                        <tr>
                          <td scope="row" style={{textAlign:"left"}}><strong>3.</strong>{t("HowToPlay3")}</td>
                          {/* <td></td> */}
                        </tr>
                        <tr>
                          <td scope="row" style={{textAlign:"left"}}><strong>4.</strong>{t("HowToPlay4")}</td>
                          {/* <td></td> */}
                        </tr>                    
                      </tbody>
                    </Table>
                  </CardBody>
                  <CardFooter>
                    <div style={sectionCardTextNC}>
                      <p className="cardHeaderFont" style={f30}><b>{t('att')}</b></p>
                      <p className="cardBodyFont" style={f20}>{t('attDesc')}{mainWallet} {t('attDesc1')}</p>
                    </div>
                  </CardFooter>
                </Card>
              </Col>
            </Row>
          </Container>
        </div>    
        { enableLiveChat ? <LiveChat license={"13064331"} /> : <div></div> }
        {/* <Modal isOpen={isDisplay} modalClassName="animepmodal" className="animemodal" style={{height:"200px", backgroundColor: "transparent"}} >
          <ModalBody style={lh50}>
            <Spinner></Spinner>
            </ModalBody>
        </Modal> */}

        <Modal isOpen={isDisplayWinGif} modalClassName="gifpmodal" >
          <ModalBody>
            <div>
            <Spinner1></Spinner1>
            <p style={{fontWeight:"bold"}}>{t("WinMessage1")}</p> {t("WinMessage2")} {blockNo}    
            <p>{t("WinMessage3")} {winAmt}</p>
            </div>
            <Button color="secondary" onClick={toggleWinGifModal}>Close</Button>

            </ModalBody>
        </Modal>
        <Modal isOpen={isDisplayLoseGif} modalClassName="gifpmodal" >
          <ModalBody>
            <div>
            <Spinner2></Spinner2>
            <p>{t("LoseMessage1")} {blockNo}</p>
            <p>{t("LoseMessage2")}</p>
            </div>
            <Button color="secondary" onClick={toggleLoseGifModal}>Close</Button>

            </ModalBody>
        </Modal>
      </div>
      </LoadingOverlay>
   </>
  );
}
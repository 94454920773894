/*!

=========================================================
* BLK Design System React - v1.2.0
=========================================================

* Product Page: https://www.creative-tim.com/product/blk-design-system-react
* Copyright 2020 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/blk-design-system-react/blob/main/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React ,{Suspense} from "react";
// reactstrap components
import {
  Button,
  Card,
  CardBody,
  CardFooter,
  CardTitle,
  Container,
  Row,
  Col,
} from "reactstrap";
import Carousel from 'react-bootstrap/Carousel';
// core components
import MainNavbar from "components/Navbars/MainNavbar.js";

import Slider from "components/Slider/slider.js";
import { useMediaQuery } from 'react-responsive';

import "../assets/scss/style.scss";

import { useTranslation } from "react-i18next";
import axios from "axios";
import Spinner from "components/Spinner/spinner";
import LiveChat from 'react-livechat'

export default function LandingPage() {
  const isDesktopOrLaptop = useMediaQuery({
    query: '(min-width: 1200px)'
  });
  const isTabletOrMobile = useMediaQuery({ 
    query: '(max-width: 1199px)' 
  });
  const smallMin = useMediaQuery({ 
    query: '(min-width: 398px)' 
  });
  const smallMax = useMediaQuery({ 
    query: '(max-width: 766px)' 
  });
  const isSmallMobile = (() => {
    if (smallMin && smallMax) {
      return true;
    } else {
      return false;
    }
  })();
  const { t, i18n } = useTranslation();
  const [poolAmt, setPoolAmt] = React.useState("");
  const [isLoading , setIsLoading]= React.useState(false);
  const [walletAddress , setWalletAddress]= React.useState("");
  const [newPlayer , setNewPlayer]= React.useState("");
  const [topPlayer ,setTopPlayer] = React.useState([]);
  const [topPlayerProfit , setTopPlayerProfit] = React.useState([]);

  const [isLogin , setIsLogin] = React.useState(false);
  const [enableLiveChat , setEnableLiveChat] = React.useState(false);
  const [mainWallet, setMainWallet] = React.useState("T...UBET");
  const [table1Wallet , setTable1Wallet] = React.useState("TQ9hGroJxD3PPCHCtrrHf17QQkn4soUBET");

  const sectionCardText = {
    color: "rgba(255, 255, 255, 0.8)",
    fontWeight: "300",
    textAlign: "center"
  };
  const f20 = {
    fontSize: "15px"
  };
  const f30 = {
    fontSize: "25px"
  };
  const f35 = {
    fontSize : "35px"
  };
  const f23 = {
    fontSize : "23px"
  };
  const fr20 = {
    fontSize: "20px"
  };
  const f18 = {
    fontSize: "18px"
  };
  const sectionCardTextNC = {
    color: "rgba(255, 255, 255, 0.8)",
    fontWeight: "300"
  };
  const mt2 = {
    marginTop : "2em"
  };
  const mbmt2 = (() => {
    if (isDesktopOrLaptop) {
      return {};
    } else {
      return {
        marginTop : "2em"
      };
    }
  })();
  const mbpt0 = (() => {
    if (isDesktopOrLaptop) {
      return {};
    } else {
      return {
        paddingTop : "unset"
      };
    }
  })();
  const mbmt0 = (() => {
    if (isDesktopOrLaptop) {
      return {};
    } else {
      return {
        marginTop : "unset"
      };
    }
  })();
  const mbminheight = (() => {
    if (isDesktopOrLaptop) {
      return {
        minHeight : "55vh"
    };
    } else {
      return {
        minHeight : "45vh"
      };
    }
  })();
  const blackText = {
    color: "#0a0a0a"
  };
  const mh4 = {
    maxHeight : "25em"
  };
  const cshadow = {
    boxShadow: "inset 0 0 0 1000px rgba(0,0,0,.6)"
  };

  const loadSpinner = (() => {
    if (isDesktopOrLaptop) {
      return {
        marginTop : "25%"
      };
    } else if (isSmallMobile) {
      return {
        marginTop : "100%"
      };
    } else if (isTabletOrMobile) {
      return {
        marginTop : "60%"
      };
    } else {
      return {
        marginTop : "25%"
      };
    }
  })();

  const copyToClipboard = () => {   
    if(window.sessionStorage.getItem("login"))
    {
      var obj = JSON.parse(window.sessionStorage.getItem("login"))
      setWalletAddress(obj.address);
    }
    navigator.clipboard.writeText("https://t.me/utron_helper_bot?start=" + walletAddress);
    alert("Copied");
  }

  const navigatePool1 = () => {
    window.open("https://tronscan.org/#/address/" + table1Wallet);
  }

  const navigatePool2 = () => {
    window.open("https://tronscan.org/#/address/TFKCZdFWxybJRbTBCT4GifBDNihkNAQQQQ");
  }

  const navigatePool3 = () => {   
      window.open("https://tronscan.org/#/address/TVSgUKULcYTEH6hy2xNZVpt5QFUeSPKKKK");
  }


  const getPool =  async () =>{
    // let response = await axios.get('https://us-central1-tron-8d709.cloudfunctions.net/pool');
    // if(response.status == 200){
    //   if(response.data){
    //     setPoolAmt(response.data.pool);
    //     setIsLoading(false);
    //   }    
    // }   
    setPoolAmt("21,383 USDT")
  }

  const getNewPlayer =  async () =>{
    // let response = await axios.get('http://18.119.28.96:5000/NewPlayers');
    // if(response.status == 200){
    //   if(response.data){
    //     setNewPlayer(response.data);
    //   }    
    // }   
    setNewPlayer("21")
  }

  const getTopPlayer =  async () =>{
    // let response = await axios.get('http://18.119.28.96:5000/TopPlayers');
    // if(response.status == 200){
    //   if(response.data){
    //     console.log(response.data);
    //     // var results = JSON.parse(response.data);     
    //     var player = [];
    //     var profit = [];
    //     for (var key in response.data) {
    //       if (response.data.hasOwnProperty(key)) {
    //         var simplify = key.charAt(0);
    //         simplify = simplify + "...";
    //         simplify = simplify + key.substr(key.length - 4);
    //         player.push(simplify)
    //         profit.push(parseFloat(response.data[key])/1000000);
    //       }
    //     }
    //     setTopPlayer(player);
    //     setTopPlayerProfit(profit); 
    //   }    
    // }   
    // setIsLoading(false);
  }


  React.useEffect(() => {
    setIsLoading(true);
    document.body.classList.toggle("landing-page");
    var hostName = window.location.hostname;

    if(hostName.includes("utron")){
      setTable1Wallet("TJSJQiogez25juTtk1JrzQA5YK7etPUUUU");
    } else if (hostName.includes("1687")){
      setTable1Wallet("TFKCZdFWxybJRbTBCT4GifBDNihkNAQQQQ");
    }

    getPool().then(() =>{
      getNewPlayer().then(()=>{
        getTopPlayer();
      });
    });
    
    var hostName = window.location.hostname;
    if(hostName.includes("utron")){
      setMainWallet("T...UUUU");
    } else if (hostName.includes("1687")){
      setMainWallet("T...QQQQ");
    }

    if(!hostName.includes("utron"))
    {
      setEnableLiveChat(true);
    }

    setIsLoading(false);

    // Specify how to clean up after this effect:
    return function cleanup() {
      document.body.classList.toggle("landing-page"); 
    };
  },[]);

  const handleIsLogin = () =>{
    setIsLogin(!isLogin);
  }
  
  React.useEffect(() =>{
    if(window.sessionStorage.getItem("login"))
    {
      var obj = JSON.parse(window.sessionStorage.getItem("login"))
      setWalletAddress(obj.address)
    }

  },[isLogin]);

  return (
    isLoading ? <div style={loadSpinner}><Spinner></Spinner></div> :
    <Suspense fallback={<h1>Loading...</h1>}>   
      <MainNavbar handleIsLogin = {handleIsLogin}/>
      {/* <Slider /> */}
      <div className="wrapper">
        <div className="page-header" style={mbminheight}>
          <img
            alt="..."
            className="path"
            src={require("assets/img/blob.png").default}
          />
          <img
            alt="..."
            className="path2"
            src={require("assets/img/path2.png").default}
          />
          <img
            alt="..."
            className="shapes triangle"
            src={require("assets/img/triunghiuri.png").default}
          />
          <img
            alt="..."
            className="shapes wave"
            src={require("assets/img/waves.png").default}
          />
          <img
            alt="..."
            className="shapes squares"
            src={require("assets/img/patrat.png").default}
          />
          <img
            alt="..."
            className="shapes circle"
            src={require("assets/img/cercuri.png").default}
          />
          <div className="content-center" >
            {/* <h2>{t('title')}</h2> */}
            <Row className="justify-content-between align-items-center text-left">
              <Col lg="12" md="12" sm="12" xs="12" className="container-fluid">
                <div className="c-indicator-pos">
                  <Carousel>
                    <Carousel.Item>
                      <img
                        className="d-block w-100"
                        src= {require("assets/img/Welcome.jpeg").default}
                        alt="First slide"
                      />
                    </Carousel.Item>
                    <Carousel.Item>
                      <img
                        className="d-block w-100"
                        src= {require("assets/img/game1.jpeg").default}
                        alt="Second slide"
                      />
                      <Carousel.Caption>
                        {/* <h3 className="buttonTitle">Game 1</h3> */}
                        <span>
                          <br/>            
                          <Button className="btn-fighter"><a href={"/game-page"}><span className="buttonFightTitle">&#9876; {t('fight')}</span></a></Button>
                        </span>
                      </Carousel.Caption>
                    </Carousel.Item>
                    {/* <Carousel.Item>
                      <img
                        className="d-block w-100"
                        src= {require("assets/img/fabien-bazanegue.jpg").default}
                        alt="Second slide"
                      />

                      <Carousel.Caption>
                        <h3 className="buttonTitle">Game 2</h3>
                        <span>
                          <br/><Button className="btn-fighter"><span className="buttonFightTitle">&#9876; {t('fight')}</span></Button>
                        </span>
                      </Carousel.Caption>
                    </Carousel.Item> */}
                    {/* <Carousel.Item>
                      <img
                        className="d-block w-100"
                        src= {require("assets/img/mark-finn.jpg").default}
                        alt="Third slide"
                      />

                      <Carousel.Caption>
                        <h3 className="buttonTitle">Game 3</h3>
                        <span>
                          <br/><Button className="btn-fighter"><span className="buttonFightTitle">&#9876; {t('fight')}</span></Button>
                        </span>
                      </Carousel.Caption>
                    </Carousel.Item> */}
                  </Carousel>
                </div>
              </Col>         
            </Row>
           
          </div>
        </div>
        <section className="section section-lg">
          {/* <section className="section"> */}
            <img
              alt="..."
              className="path"
              src={require("assets/img/path4.png").default}
            />
            <Container>
              <Row className="justify-content-center">
                <Col lg="12" md="12" sm="12" xs="12">
                  <h1 className="text-center miniHeaderFont">{t('section1')}</h1>
                </Col>
              </Row>
              <Row className="row-grid justify-content-between">
                <Col className="mt-lg-12" md="12">
                  <Row>
                    <Col className="px-6 py-6" lg="2">
                    </Col>
                    <Col className="px-6 py-6" lg="4" md="6" sm="12">
                      <Card className="card-coin card-plain">
                        <CardBody style={cshadow}>
                          <Row className="justify-content-center">
                            <Col lg="12">
                              <p className="text-center miniHeaderFont" style={f23}><strong>{t('box1')}</strong></p>
                            </Col>
                            <Col md="12" xs="12">
                              <div className="numbers numberFont">
                                <CardTitle tag="p" className="text-center" style={fr20}>{poolAmt}</CardTitle>                               
                              </div>
                            </Col>
                          </Row>
                        </CardBody>
                      </Card>
                    </Col>
                    <Col className="px-6 py-6" lg="4" md="6" sm="12">
                      <Card className="card-coin card-plain">
                        <CardBody style={cshadow}>
                          <Row className="justify-content-center">
                              <Col lg="12">
                                <p className="text-center miniHeaderFont" style={f23}><strong>{t('box2')}</strong></p>
                              </Col>
                              <Col md="12" xs="12">
                                <div className="numbers numberFont">
                                  <CardTitle tag="p" className="text-center" style={fr20}>{newPlayer}</CardTitle>                         
                                </div>
                              </Col>
                            </Row>
                        </CardBody>
                      </Card>
                    </Col>
                    <Col className="px-6 py-6" lg="2">
                    </Col>
                  </Row> 
                  <Row style={mt2}>
                    <Col className="px-6 py-6" lg="3" md="3">
                    </Col>
                    <Col className="px-6 py-6" lg="6" md="6" sm="12">
                      <Card className="card-coin card-plain">
                        <CardBody style={cshadow}>
                          <Row>
                            <Col md="6" xs="6">
                                <CardTitle className="miniHeaderFont" tag="p" style={f23}><strong>{t('box3Desc1')}</strong></CardTitle>
                                <p />
                                {
                                  topPlayer.map( (player,index) => <p className="numberFont" key={index} style={fr20}>{index}. {player}</p>)
                                }
                                {/* <p className="numberFont" style={fr20}>1. 0x...23</p>
                                <p className="numberFont" style={fr20}>2. 0x...a1</p>
                                <p className="numberFont" style={fr20}>3. 0x...a3</p>
                                <p className="numberFont" style={fr20}>4. 0x...71</p>
                                <p className="numberFont" style={fr20}>5. 0x...51</p>
                                <p className="numberFont" style={fr20}>6. 0x...c1</p>
                                <p className="numberFont" style={fr20}>7. 0x...b1</p>
                                <p className="numberFont" style={fr20}>8. 0x...a4</p>
                                <p className="numberFont" style={fr20}>9. 0x...c9</p>
                                <p className="numberFont" style={fr20}>10. 0x...b5</p> */}
                            </Col>
                            <Col md="6" xs="6">
                                <CardTitle className="miniHeaderFont" tag="p" style={f23}><strong>{t('box3Desc2')}</strong></CardTitle>
                                <p />
                                {
                                  topPlayerProfit.map( (profit,index) => <p className="numberFont" key={index} style={fr20}>{profit}</p>)
                                }
                                {/* <p className="numberFont" style={fr20}>500 USDT</p>
                                <p className="numberFont" style={fr20}>400 USDT</p>
                                <p className="numberFont" style={fr20}>300 USDT</p>
                                <p className="numberFont" style={fr20}>250 USDT</p>
                                <p className="numberFont" style={fr20}>200 USDT</p>
                                <p className="numberFont" style={fr20}>150 USDT</p>
                                <p className="numberFont" style={fr20}>100 USDT</p>
                                <p className="numberFont" style={fr20}>50 USDT</p>
                                <p className="numberFont" style={fr20}>40 USDT</p>
                                <p className="numberFont" style={fr20}>20 USDT</p> */}
                            </Col>
                          </Row>
                        </CardBody>
                      </Card>
                    </Col>
                    <Col className="px-6 py-6" lg="3" md="3">
                    </Col>
                  </Row>             
                </Col>        
              </Row>
            </Container>
          {/* </section> */}
        </section>
        <section className="section section-lg">
          {/* <img
            alt="..."
            className="path"
            style={mh4}
            src={require("assets/img/path4.png").default}
          /> */}
          <img
            alt="..."
            className="path2"
            src={require("assets/img/path5.png").default}
          />
          <img
            alt="..."
            className="path3"
            src={require("assets/img/path2.png").default}
          />
          <Container>
            <Row className="justify-content-center">
              <Col lg="12" md="12" sm="12" xs="12">
                <Row className="row-grid justify-content-center">
                  <Col lg="4" md="12" sm="12" xs="12">
                    <Card className="card-coin card-plain" style={mbmt2}>
                      <CardBody style={cshadow}>
                        <div style={sectionCardText}>
                          <p className="miniHeaderFont" style={f30}><strong>{t('box4')}</strong></p>
                          <p className="cardBodyFont" style={f18}> {t('box4Desc1')}  
                          <a id='pool1' style={{color:"yellow" ,cursor: "pointer"}} onClick={navigatePool1}>{mainWallet}</a> 
                          {/* , <a id='pool2' style={{color:"yellow" ,cursor: "pointer"}} onClick={navigatePool2}>QQQQ</a>  */}
                          {/* , <a id='pool3' style={{color:"yellow" ,cursor: "pointer"}} onClick={navigatePool3}>KKKK</a>    */}
                          {t('box4Desc2')}</p>
                        </div>
                      </CardBody>
                    </Card>   
                  </Col>
                  <Col lg="4" md="12" sm="12" xs="12">
                    <Card className="card-coin card-plain" style={mbmt2}>
                      <CardBody style={cshadow}>
                        <div style={sectionCardText}>
                          <p className="miniHeaderFont" style={f30}><strong>{t('box5')}</strong></p>
                          <p className="cardBodyFont" style={f18}>{t('box5Desc')}</p>
                        </div>
                      </CardBody>
                    </Card>           
                  </Col>
                  <Col lg="4" md="12" sm="12" xs="12">
                  <Card className="card-coin card-plain" style={mbmt2}>
                      <CardBody style={cshadow}>
                        <div style={sectionCardText}>
                          <p className="miniHeaderFont" style={f30}><strong>{t('box6')}</strong></p>
                          <p className="cardBodyFont" style={f18}>{t('box6Desc')}</p>
                        </div>
                      </CardBody>
                    </Card>          
                  </Col>
                </Row>
              </Col>
            </Row>
          </Container>
        </section>
        <section className="section section-lg section-safe" style={mbpt0}>
          <img
            alt="..."
            className="path"
            style={mh4}
            src={require("assets/img/path5.png").default}
          />
          <Container>          
             <Row className="justify-content-between">
              <Col lg="12" md="12" sm="12" xs="12">
                <Card className="card-coin card-plain" style={mbmt0}>
                  <CardBody style={cshadow}>
                  <hr className="line-success" />
                  <h3 className="miniHeaderFont bolderText f30">{t('referral')}</h3>
                  <p></p>
                  <p className="miniHeaderFont bolderText f25">{t('referralDesc1')}</p>
                  <p className="cardBodyFont f18">{t('referralDesc2')}</p>
                  <br></br>
                  <p className="miniHeaderFont bolderText f25">{t('referralLink')}</p>
                  <div className="icon icon-success mb-2">
                    <b style={{color:"white"}}>{"https://t.me/utron_helper_bot?start=" + walletAddress}</b>
                    <b>  </b>
                    <i className="tim-icons icon-paper" onClick={copyToClipboard}/>
                  </div>                
                  </CardBody>
                  <CardFooter style={cshadow}>
                    <div style={sectionCardTextNC}>
                      <p className="miniHeaderFont bolderText f25" style={f30}><b>{t('att')}</b></p>
                      <p className="cardBodyFont f18" style={f30}>{t('attDesc')}{mainWallet} {t('attDesc1')}</p>
                    </div>
                  </CardFooter>
                </Card>
              </Col>
            </Row>
          </Container>

          {enableLiveChat ? <LiveChat license={13064331} /> : <div></div>}
          
        </section>
        {/* <Footer /> */}
        {/* <TronDetails></TronDetails> */}
      </div>
    </Suspense>
  );
}

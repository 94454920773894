/*!

=========================================================
* BLK Design System React - v1.2.0
=========================================================

* Product Page: https://www.creative-tim.com/product/blk-design-system-react
* Copyright 2020 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/blk-design-system-react/blob/main/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React ,{useState} from "react";
import { Link } from "react-router-dom";
// reactstrap components
import {
  Button,
  Collapse,
  NavbarBrand,
  Navbar,
  NavItem,
  NavLink,
  Nav,
  Container,
  Row,
  Col,
  UncontrolledTooltip,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  UncontrolledDropdown,
} from "reactstrap";

import { useTranslation } from "react-i18next";
import { useMediaQuery } from 'react-responsive';

export default function MainNavbar(props) {

  const isDesktopOrLaptop = useMediaQuery({
    query: '(min-width: 1024px)'
  });
  const [collapseOpen, setCollapseOpen] = React.useState(false);
  const [collapseOut, setCollapseOut] = React.useState("");
  const [color, setColor] = React.useState("navbar-transparent");
  const { t, i18n } = useTranslation();
  const [language , setLanguage] = React.useState("");
  const tabletMin = useMediaQuery({ 
    query: '(min-width: 1024px)' 
  });
  const tabletMax = useMediaQuery({ 
    query: '(max-height: 1366px)' 
  });
  const isTablet = (() => {
    if (tabletMin && tabletMax) {
      return true;
    } else {
      return false;
    }
  })();
  const blackText = {
    color: "#0a0a0a"
  };
  const centerText = {
    textAlign: "center"
  };
  const iconSpacing = (() => {
    if (isDesktopOrLaptop) {
      return {
      };
    } else {
      return {
        padding : "1em",
        border: "1px solid rgba(0,0,0,0.4)"
      };
    }
  })();
  const mbcolour = (() => {
    if (isDesktopOrLaptop) {
      return {
        color: "unset"
      };
    } else {
      return {
        color : "black",
        fontWeight : "500"
      };
    }
  })();
  const mbview = (() => {
    if (isDesktopOrLaptop) {
      return {
      };
    } else {
      return {
        display: "none"
      };
    }
  })();
  const mbicon = (() => {
    if (isDesktopOrLaptop) {
      return {
      };
    } else {
      return {
        maxHeight: "32px",
        maxWidth: "32px"
      };
    }
  })();
  const mbicontext = (() => {
    if (isDesktopOrLaptop) {
      return {
        color: "black",
        fontWeight: "500"
      };
    } else {
      return {
        marginTop:"0.5em",
        color: "black",
        fontWeight: "500"
      };
    }
  })();
  const iPadmargins = (() => {
    if (isTablet) {
      return {
        marginRight:"6em"
      };
    } else {
      return {
      };
    }
  })();
  const [myDetails, setMyDetails] = useState({
    name: 'none',
    address: 'none',
    balance: 0,
    frozenBalance: 0,
    network: 'none',
    link: 'false',
    isLogin: false
  });

  React.useEffect(() => {
  
    var lang = i18n.language;
    if(lang == "cn"){
      setLanguage("CN");
    }else{
      setLanguage("EN");
    }

    if(window.sessionStorage.getItem("login")){
      setMyDetails(JSON.parse(window.sessionStorage.getItem("login")));
    }

    getWalletDetails();

    const interval=setInterval(()=>{
      getWalletDetails()
     },10000)

    window.addEventListener("scroll", changeColor);
    
    return function cleanup() {
      window.removeEventListener("scroll", changeColor);
      clearInterval(interval)
    };

  },[]);

  const changeLanguage = (language) => {
    setLanguage(language.toUpperCase());
    i18n.changeLanguage(language);
  };

  const changeColor = () => {
    if (
      document.documentElement.scrollTop > 99 ||
      document.body.scrollTop > 99
    ) {
      setColor("bg-info");
    } else if (
      document.documentElement.scrollTop < 100 ||
      document.body.scrollTop < 100
    ) {
      setColor("navbar-transparent");
    }
  };
  const toggleCollapse = () => {
    document.documentElement.classList.toggle("nav-open");
    setCollapseOpen(!collapseOpen);
  };
  const onCollapseExiting = () => {
    setCollapseOut("collapsing-out");
  };
  const onCollapseExited = () => {
    setCollapseOut("");
  };
  function bgdark(e){
    e.target.style.background = 'linear-gradient(90deg, rgba(255,255,255,1) 0%, rgba(255,165,2,1) 50%, rgba(255,255,255,1) 100%)';
  }
  function bgdefault(e){
    e.target.style.background = '#ffffff';
  }

  const getBalance = async () => {
    //if wallet installed and logged , getting TRX token balance
    if (window.tronWeb && window.tronWeb.ready) {
      let walletBalances = await window.tronWeb.trx.getAccount(
        window.tronWeb.defaultAddress.base58
      );
      return walletBalances;
    } else {
      return 0;
    }
  };

  const getWalletDetails = async () => {
    console.log("connect wallet...");
    
    if (window.tronWeb) {
      //checking if wallet injected
      if (window.tronWeb.ready) {
        // let tempBalance = await getBalance();
       
        //we have wallet and we are logged in
        setMyDetails({
          name: window.tronWeb.defaultAddress.name,
          address: window.tronWeb.defaultAddress.base58,
          // balance: tempBalance.balance / 1000000,
        //   frozenBalance: tempFrozenBalance / 1000000,
          network: window.tronWeb.fullNode.host,
          link: 'true',
          isLogin: true
        });

        window.sessionStorage.setItem("login", JSON.stringify({
          name: window.tronWeb.defaultAddress.name,
          address: window.tronWeb.defaultAddress.base58,
          // balance: tempBalance.balance / 1000000,
        //   frozenBalance: tempFrozenBalance / 1000000,
          network: window.tronWeb.fullNode.host,
          link: 'true',
          isLogin: true
        }));

        console.log("connected");
        
        props.handleIsLogin(true);
      } else {
        //we have wallet but not logged in
        setMyDetails({
          name: 'none',
          address: 'none',
          balance: 0,
          frozenBalance: 0,
          network: 'none',
          link: 'false',
          isLogin:false
        });
        
        console.log("not connected");
		// alert("Please Connect to your Wallet");

      }
    }
    // else{
    //   alert("Please install TronLink...");
    // }
  };

  // const Loginwallet = () =>{
  //   // setInterval(async () =>{
  //     getWalletDetails();
  //   // },2000);
  
  // }

  return (
    <Navbar className={"fixed-top " + color} color-on-scroll="100" expand="lg">
      <Container className="ipmarginleftset">
        <div className="navbar-translate">
          <NavbarBrand to="/" id="navbar-brand" tag={Link}>
            <span>
              {isDesktopOrLaptop ? 
              <img src={require("assets/img/logo.png").default} width="120px" height="30px" style={{marginRight:"0.5em"}}></img> 
              : <img src={require("assets/img/logo.png").default} width="100px" height="25px"></img>}
            </span>
          </NavbarBrand>
          {/* <UncontrolledTooltip placement="bottom" target="navbar-brand">
            Designed and Coded by Creative Tim
          </UncontrolledTooltip> */}

            {/* { !isDesktopOrLaptop ?  myDetails.isLogin == false ? 
              <Button
                className="nav-link d-lg-block mbview dtview"
                color="primary"
                target="_blank"
                onClick={Loginwallet}        
              >
                <i className="tim-icons icon-spaceship" />{t('login')}
              </Button> : <p>{myDetails.name}</p>
              :""
            }  */}
            

          <button
            aria-expanded={collapseOpen}
            className="navbar-toggler navbar-toggler"
            onClick={toggleCollapse}
          >
            <span className="navbar-toggler-bar bar1" />
            <span className="navbar-toggler-bar bar2" />
            <span className="navbar-toggler-bar bar3" />
          </button>
        </div>
        <Collapse
          className={"justify-content-end " + collapseOut}
          navbar
          isOpen={collapseOpen}
          onExiting={onCollapseExiting}
          onExited={onCollapseExited}
          style={iPadmargins}
        >
          <div className="navbar-collapse-header">
            <Row>
              <Col className="collapse-brand" xs="6">
                <a href="#" onClick={(e) => e.preventDefault()}>
                  
                </a>
              </Col>
              <Col className="collapse-close text-right" xs="6">
                <button
                  aria-expanded={collapseOpen}
                  className="navbar-toggler"
                  onClick={toggleCollapse}
                >
                  <i className="tim-icons icon-simple-remove" />
                </button>
              </Col>
            </Row>
          </div>
          <Nav navbar>
            <UncontrolledDropdown nav >
              <DropdownToggle
                caret
                color="default"
                href="#"
                nav
                style={mbcolour}
                onClick={(e) => e.preventDefault()}
              >
                {/* <i className="fa fa-cogs d-lg-none d-xl-none" /> */}
                {t('games')}
              </DropdownToggle>
              <DropdownMenu className="dropdown-with-icons">
                <DropdownItem href="/game-page" onMouseEnter={bgdark} onMouseLeave={bgdefault} style={{color:"black", fontWeight: "500"}}>
                  <i className="tim-icons icon-paper" />
                  {t('game1')}
                </DropdownItem>     
                <DropdownItem onMouseEnter={bgdark} onMouseLeave={bgdefault} style={{color:"black", fontWeight: "500"}}>
                  <i className="tim-icons icon-paper" />
                  {t('game2')}
                </DropdownItem>
                <DropdownItem onMouseEnter={bgdark} onMouseLeave={bgdefault} style={{color:"black", fontWeight: "500"}}>
                  <i className="tim-icons icon-paper" />
                  {t('game3')}
                </DropdownItem>  
              </DropdownMenu>
            </UncontrolledDropdown>
            <UncontrolledDropdown nav  >
              <DropdownToggle
                caret
                color="default"
                href="#"
                nav
                style={mbcolour}
                onClick={(e) => e.preventDefault()}
              >
                {language}
              </DropdownToggle>
              <DropdownMenu className="dropdown-with-icons">
                <DropdownItem onClick={() => changeLanguage("en")} onMouseEnter={bgdark} onMouseLeave={bgdefault} style={Object.assign(centerText)}>
                  <Row>
                    <Col xl="4" lg="4" md="4" sm="4" xs="4"><img src={require("assets/img/us.svg").default} style={mbicon} /></Col>
                    <Col xl="2" lg="2" md="4" sm="2" xs="4" style={{padding:"unset"}}><p style={mbicontext}>EN</p></Col>
                  </Row>
                </DropdownItem>
                <DropdownItem onClick={() => changeLanguage("cn")} onMouseEnter={bgdark} onMouseLeave={bgdefault} style={Object.assign(centerText)}>
                  <Row>
                    <Col xl="4" lg="4" md="4" sm="4" xs="4"><img src={require("assets/img/cn.svg").default} style={mbicon} /></Col>
                    <Col xl="2" lg="2" md="4" sm="2" xs="4" style={{padding:"unset"}}><p style={mbicontext}>CN</p></Col>
                  </Row>
                </DropdownItem>  
              </DropdownMenu>
            </UncontrolledDropdown>           

            {/* { isDesktopOrLaptop ? myDetails.isLogin == false ? <NavItem>
              <Button
                className="nav-link d-lg-block"
                color="primary"
                target="_blank"  
                style={Object.assign(blackText, iconSpacing , mbview)}
                onClick={Loginwallet}        
              >
                <i className="tim-icons icon-spaceship" />{t('login')}
              </Button>
            </NavItem> : <p style={{alignSelf:"center"}}>{myDetails.name}</p>
            :""
            }  */}

          </Nav>
        </Collapse>
      </Container>
    </Navbar>
  );
}
